<template>
  <v-app id="inspire" class="horizontalstyle">
    <vertical-header :user="user" />
    <v-main>
      <v-container fluid class="page-wrapper">
        <router-view />
      </v-container>
    </v-main>
    <vertical-sidebar />
    <base-loading />
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import VerticalSidebar from './sidebar/VerticalSidebar.vue'
import VerticalHeader from './header/VerticalHeader.vue'

export default {
  components: {
    VerticalSidebar,
    VerticalHeader
  },
  name: 'MainLayout',

  props: {
    source: String
  },
  data: () => ({
    expandOnHover: false
  }),
  computed: {
    ...mapState(['customizerDrawer', 'setHorizontalLayout', 'user'])
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: 'setCustomizerDrawer'
    })
  }
}
</script>
