export default [
  {
    name: 'PRINCIPAL',
    level: 3
  },
  {
    name: 'ADMIN',
    level: 5
  },
  {
    name: 'FINANCIAL',
    level: 4
  },
  {
    name: 'TECHNICAL',
    level: 4
  },
  {
    name: 'ORG_PEDAGOGICAL_COORDINATOR',
    level: 4
  },
  {
    name: 'SCHOOL_PEDAGOGICAL_COORDINATOR',
    level: 2
  },
  {
    name: 'TEACHER',
    level: 1
  },
  {
    name: 'STUDENT',
    level: 1
  }
]
