<template>
  <v-app-bar
    app
    fixed
    flat
    clipped-left
    clipped-right
    :color="navbarColor"
    :dark="navbarColor !== 'white'"
  >
    <v-toolbar-title @click="goToHome()" class="align-center d-flex logo-width pointer" v-if="navbarColor !== 'white'">
      <span class="logo-icon">
        <img src="@/assets/images/logo/logo-pruvo-icon.png" class="mt-2" />
      </span>
      <span class="logo-text ml-2 d-none d-md-block">
        <img src="@/assets/images/logo/logo-pruvo-text.png" class="mt-2" />
      </span>
    </v-toolbar-title>
    <!---/Toggle sidebar part -->
    <div class="d-md-none">
      <v-app-bar-nav-icon
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!sidebarDrawer)
            : $emit('input', !value)
        "
      />
    </div>

    <v-card v-if="showSelectOrganization" color="primary" class="searchInput rounded-0">
      <template>
        <v-autocomplete
              no-filter
              :items="organizations"
              v-model="organizationSelected"
              item-value="metadata.id"
              class="mt-6"
              @change="changeOrganization"
              label="Selecione a organização"
              item-text="data.company.name.trade"
              @click:append="showSelectOrganization=false"
              @focus="bindScrollListener"
              append-icon="mdi-close"
            ></v-autocomplete>
            <v-progress-linear indeterminate v-if="loadOrganization" color="white"></v-progress-linear>
      </template>
    </v-card>

    <v-spacer />
      <v-btn dark icon @click="showChat()" class="mr-1">
        <v-icon>mdi-chat-question</v-icon>
      </v-btn>
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-badge color="red" dot v-if="notifications && notifications[0]">
            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <template v-else>
            <v-icon>mdi-bell</v-icon>
          </template>
        </v-btn>
      </template>

      <v-list>
        <h4 class="px-5 py-3 pt-2 font-weight-medium title">Notificações</h4>
        <v-divider></v-divider>
        <template v-if="notifications && notifications.length">
          <v-list-item v-for="(item, i) in notifications" :key="i" @click="href">
            <v-list-item-title>
              <div class="d-flex align-center py-3">
                <div class>
                  <v-btn class="mr-3" depressed fab small dark :color="item.iconbg">
                    <v-icon dark>{{ item.icon }}</v-icon>
                  </v-btn>
                </div>
                <div>
                  <h4 class="font-weight-medium">{{ item.title }}</h4>
                  <span
                    class="text--secondary caption descPart d-block text-truncate"
                  >{{ item.desc }}</span>
                  <small class="text--secondary">{{ item.time }}</small>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-else>
          <v-list-item>
            <v-list-item-title>
              <div>
                <span
                  class="text--secondary caption descPart d-block text-truncate"
                >Nenhuma notificação</span>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-else>
      </v-list>
    </v-menu>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">

        <v-btn dark icon class="mr-1" ref="touch" @click="clickOrganization()"  v-bind="attrs"
          v-on="on"
        >
          <v-avatar size="40" color="#fff">
            <img v-if="activeOrganization.data && activeOrganization.data.company.logo" :src="activeOrganization.data.company.logo" />
            <v-icon v-else color="primary">mdi-home-city-outline</v-icon>
          </v-avatar>
        </v-btn>
      </template>
      <span> {{activeOrganization.data && activeOrganization.data.company.name ? activeOrganization.data.company.name.trade : 'Instituição sem nome'.toUpperCase()}} </span>
    </v-tooltip>
    <v-menu bottom left offset-y origin="top right" transition="scale-transition">
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40" color="#fff">
            <img v-if="user.me && user.me.data.avatar" :src="user.me.data.avatar" />
            <v-icon v-else color="primary">mdi-account</v-icon>
          </v-avatar>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          :href="item.href"
          :to="item.to"
          @click="action(item.action)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default () {
        return {
          me: {
            avatar: null,
            email: '',
            name: '',
            id: ''
          }
        }
      }
    }
  },
  data: () => ({
    loadOrganization: false,
    showLogo: true,
    showSearch: false,
    showSelectOrganization: false,
    organizationSelected: [],
    timer: null,
    organizationPage: 1,
    notifications: [
    ],
    messages: [
      {
        title: 'José da Silva',
        avatar: '1',
        avatarStatus: 'success',
        desc: 'Olá John. Poderia verificar a sua nota no sistema.',
        time: '01/01/2021 9:30'
      }
    ],
    userprofile: [
      { title: 'Meu perfil', to: '/user/my-profile' },
      { title: 'Sair', action: 'logout' }
    ],
    href () {
      return undefined
    }
  }),
  mounted () {
    const id = localStorage.getItem('ACTIVE_ORGANIZATION')

    this.loadChat()

    document.onkeydown = this.KeyPress
    this.organizationSelected = this.organizations.find(
      x => x.metadata.id === id
    )
    this.$refs.touch.$el.addEventListener('touchstart', () => {
      this.touchstart()
    })
    this.$refs.touch.$el.addEventListener('touchmove', () => {
      this.touchend()
    })
    this.$refs.touch.$el.addEventListener('touchend', () => {
      this.touchend()
    })
  },

  computed: {
    ...mapState(['navbarColor', 'sidebarDrawer']),
    ...mapGetters({
      organizations: 'organization/organizations',
      activeOrganization: 'organization/activeOrganization'
    })
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: 'setSidebarDrawer'
    }),
    ...mapMutations(['setLoading']),
    ...mapActions('organization', ['getOrganizations', 'getOrganization']),
    clickOrganization () {
      if (!this.$vuetify.breakpoint.mobile && this.organizations.length > 1) this.showSelectOrganization = true
    },
    bindScrollListener () {
      const token = localStorage.getItem('jwtToken')
      setTimeout(() => {
        const scroll = document.body.querySelectorAll('.v-menu__content')[0]
        scroll.addEventListener('scroll', async () => {
          if (scroll.scrollHeight - scroll.clientHeight < scroll.scrollTop + 1) {
            this.organizationPage++
            this.organizationsLoading = true
            try {
              await this.$store.dispatch('organization/getOrganizations', { token, page: this.organizationPage })
              this.organizationsLoading = false
            } catch (error) {
            }
          }
        })
      }, [500])
    },
    goToHome () {
      if (this.$router.currentRoute.path !== '/organization') {
        this.$router.push('/')
      }
    },
    KeyPress (e) {
      if (e.keyCode === 90 && e.ctrlKey) this.showSelectOrganization = true
    },
    touchstart () {
      this.timer = setTimeout(() => { this.showSelectOrganization = true }, 1000)
    },
    touchend () {
      if (this.timer) { clearTimeout(this.timer) }
    },
    changeOrganization (newOrganizationId) {
      const id = localStorage.getItem('ACTIVE_ORGANIZATION')

      if (newOrganizationId === id) return

      this.loadOrganization = true
      this.getOrganization(newOrganizationId).then(res => {
        this.showSelectOrganization = false
        this.$route.path === '/organization' ? location.reload() : this.$router.push('/organization')
      }).catch(() => {
        this.showSelectOrganization = false
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      })
    },
    action (key) {
      if (key === 'logout') {
        this.logout()
      }
    },
    logout () {
      this.$store.dispatch('setDataAuth', {})
      this.$store.dispatch('organization/clearOrganization')
      this.$axios.defaults.headers.Authorization = null
      this.$axios.defaults.headers.common.Authorization = null
      window.fcWidget.destroy()
      localStorage.clear()
      this.$router.push({ path: '/login' })
      window.location.reload(true)
    },
    showChat () {
      window.fcWidget.open()
      window.fcWidget.show()
    },
    loadChat () {
      window.fcWidget.init({
        token: '443b3225-d634-443c-a24d-60d52455b393',
        host: 'https://wchat.freshchat.com',
        config: {
          headerProperty: {
            hideChatButton: true
          }
        }
      })
    }
  }
}
</script>

<style>
.searchInput {
  position: absolute;
  width: 100%;
  height: 80px;
  top: 0;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descPart {
  max-width: 220px;
}
.pointer:hover {
  cursor: pointer
}
</style>
