export default [

  //  1 professor
  //  2 pedagogo (escola)
  //  3 diretor
  //  4 pedagogo (instituição), financeiro, técnico
  //  5 admin

  {
    title: 'Administração',
    icon: 'mdi-home-city-outline',
    to: '/organization',
    level: 5
  },
  {
    title: 'Usuários',
    icon: 'mdi-account',
    to: '/users',
    level: 5
  },
  {
    title: 'Escolas',
    icon: 'mdi-home-outline',
    to: '/school',
    level: 1
  },
  {
    title: 'Questões',
    icon: 'mdi-comment-question-outline',
    to: '/question/list',
    level: 1
  },
  {
    title: 'Avaliações',
    icon: 'mdi-text-box-outline',
    to: '/exam/list',
    level: 1
  },
  {
    title: 'Aplicações',
    icon: 'mdi-calendar-clock-outline',
    to: '/applications',
    level: 1
  },
  {
    title: 'Matrizes de referência',
    icon: 'mdi-file-document-multiple-outline',
    to: '/ReferenceMatrix',
    level: 5
  },
  {
    title: 'Material de apoio',
    icon: 'mdi-book-multiple-outline',
    to: '/support-material',
    level: 1,
    isEditoraLt: true
  }
]
