<template>
  <v-navigation-drawer
    v-model="sidebarDrawer"
    :dark="sidebarColor !== 'white'"
    :color="sidebarColor"
    mobile-breakpoint="960"
    clipped
    :right="$vuetify.rtl"
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
  >
    <v-list expand nav class="mt-1">
      <template v-for="(item, i) in getItems">
        <!---If Sidebar Caption -->
        <v-row v-if="item.header" :key="item.header" align="center">
          <v-col cols="12">
            <v-subheader v-if="item.header" class="d-block text-truncate">
              {{
              item.header
              }}
            </v-subheader>
          </v-col>
        </v-row>
        <!---If Sidebar Caption -->
        <base-item-group v-else-if="item.children" :key="`group-${i}`" :item="item"></base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import VerticalSidebarItems from './VerticalSidebarItems'
import Level from './Level'
import collect from 'collect.js'
import EditoraLt from '@/utils/editoraLt'

export default {
  name: 'VerticalSidebar',
  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    Level,
    items: VerticalSidebarItems,
    organizationId: localStorage.getItem('ACTIVE_ORGANIZATION'),
    isEditoraLt: false,
    domains: []
  }),
  created () {
    this.mountUserLevel()
    this.getOrganizationDomains()
  },
  methods: {
    mountUserLevel () {
      let userLevel = 0
      let level = 0
      if (this.user.me.data.is_super_admin) userLevel = 5
      this.user.me.data.acl.forEach(e => {
        this.Level.forEach(roleLevels => {
          if (e.roles.includes(roleLevels.name)) level = roleLevels.level
          if (level > userLevel) userLevel = level
        })
      })
      this.$store.commit('setUserLevel', userLevel)
    },
    getOrganizationDomains () {
      this.$axios.get(`/organizations/${this.organizationId}`)
        .then(response => {
          this.domains = response.data.data.domains
        })
    }
  },
  computed: {
    ...mapState(['sidebarColor', 'sidebarBg', 'user']),
    sidebarDrawer: {
      get () {
        return this.$store.state.sidebarDrawer
      },
      set (val) {
        this.$store.commit('setSidebarDrawer', val)
      }
    },
    getUserLevel () {
      return this.$store.getters.getUserLevel
    },
    hasEditoraLtDomain () {
      const lt = new EditoraLt()
      return lt.activeOrganizationHasDomain(this.domains)
    },
    getItems () {
      return collect(this.items)
        .where('level', '<=', this.getUserLevel)
        .when(!this.hasEditoraLtDomain, collection => {
          return collection.where('isEditoraLt', false)
        })
        .all()
    }
  },
  watch: {
    '$vuetify.breakpoint.smAndDown': function (val) {
      this.$emit('update:expandOnHover', !val)
    }
  }
}
</script>
<style >
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
}
#main-sidebar .v-navigation-drawer__border {
  display: none;
}
#main-sidebar .v-list {
  padding: 8px 15px;
}
#main-sidebar .v-list-item__icon--text,
#main-sidebar .v-list-item__icon:first-child {
  justify-content: center;
  text-align: center;
  width: 20px;
}
</style>
